<template>
  <v-form ref="formCity" @submit.prevent="save">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          label="Nome"
          v-model="city.name"
          :rules="requiredRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          label="Região"
          :items="regions"
          item-text="name"
          item-value="id"
          :loading="regions.length < 1"
          v-model="city.region_id"
          :rules="requiredRules"
        ></v-select>
      </v-col>
    </v-row>
    <h3>Atendimento a praça</h3>
    <v-row>
      <v-col cols="12" md="2">
        <v-checkbox label="Segunda" v-model="city.monday" />
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox label="Terça" v-model="city.tuesday" />
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox label="Quarta" v-model="city.wednesday" />
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox label="Quinta" v-model="city.thursday" />
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox label="Sexta" v-model="city.friday" />
      </v-col>
    </v-row>
    <v-btn color="success" type="submit" :loading="saving">
      Salvar
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    city: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      saving: false,
      regions: [],
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  mounted() {
    this.getRegions();
  },
  methods: {
    handleButton(status) {
      this.saving = status;
    },
    save() {
      if (this.$refs.formCity.validate()) {
        this.$emit("save", this.city);
      }
    },
    getRegions() {
      this.$http
        .get("api/regions")
        .then((res) => {
          this.regions = res.data;
        })
        .catch((err) => {
          console.log(err);

          this.$side({
            type: "error",
            msg: "Não foi possível carregar as regiões",
            duration: 4000,
          });
        });
    },
  },
};
</script>

<style scoped></style>
