<template>
  <div>
    <h1>Nova Cidade</h1>

    <v-btn
      color="error"
      title="Voltar"
      @click="$router.push('/cidades')"
      class="ma-2"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <Form ref="formCreateCity" @save="save" :city="city" />
  </div>
</template>

<script>

import Form from '../components/Form';

  export default {
    components: {
      Form,
    },
    data() {
      return {
        city: {
          name: '',
          city_id: ''
        }
      }
    },
    methods: {
      cleanForm() {
        this.city = {
          name: '',
          city_id: ''
        }
      },
      save(city) {
        this.$refs.formCreateCity.handleButton(true);
        this.$http
          .post('api/cities', city)
          .then(() => {
            this.$refs.formCreateCity.handleButton(false);
            this.cleanForm();
            this.$side({
              type    : 'success',
              msg     : 'Salvo com sucesso',
              duration: 4000
            });
          }).catch(err => {
            this.$refs.formCreateCity.handleButton(false);
            console.log(err);

            this.$side({
              type    : 'error',
              msg     : 'Erro ao salvar a cidade',
              duration: 4000
            });
          });
      }
    },
  }
</script>

<style scoped>

</style>